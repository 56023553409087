<template>
  <div style="min-height:100vh;padding:.2rem">
    <titleLink title="评价详情" :onClick="onClickBack"></titleLink>
    <card style="margin-top:.5rem;padding:.4rem;line-height:.3rem">
      <template #content>
        <van-row>
            <van-col class="feedback_title_name" span="24">
              <div style="padding:.1rem 0 .1rem 0">
                {{currentFeedback.feedback_content == ''?'暂无评价':currentFeedback.feedback_content}}
              </div>
            </van-col>
            <van-col span="24">
              <van-row type="flex">
                <van-tag v-for="(tag, idx) in currentFeedback.tags" :key="'tag:' + idx" round type="primary"
                  style="margin-right:.1rem">
                  {{tag}}
                </van-tag>
              </van-row>
            </van-col>
            <van-col span="24">
              <van-rate v-model="currentFeedback.rate_star" color="#ffd21e"
                void-icon="star" disabled-color="#eee"
                readonly :size="25"
                style="line-height:25px;"
                allow-half/>
            </van-col>
            <van-col class="feedback_comment_tag" span="12" style="height:.2rem;line-height:.2rem;padding-top:0">
              {{user.user == undefined? '':user.user.username}}
            </van-col>
            <van-col class="feedback_comment_tag" span="12" style="text-align:right;height:.2rem;">
              {{currentFeedback.updated_at}}
            </van-col>
        </van-row>
         <van-divider content-position="left">回复</van-divider>
        <van-row v-for="(item, idx) in currentFeedback.replies" :key="idx" style="margin-top:10px">
          <van-col class="feedback_title_name" span="24" style="height:.3rem;">
            {{item.content}}
          </van-col>
          <van-col class="feedback_comment_tag" span="12" style="height:.2rem;">
            {{item.user_name}}
          </van-col>
          <van-col class="feedback_comment_tag" span="12" style="text-align:right;height:.2rem;">
            {{item.reply_at}}
          </van-col>
        </van-row>
        <van-row v-if="currentFeedback.replies == undefined || currentFeedback.replies.length == 0">
          <van-empty description="暂无回复"/>
        </van-row>
      </template>
    </card>
    
  </div>
</template>

<script>
import card from "@/views/components/Card"
import {mapGetters} from "vuex"
import tourInfoTop from "@/views/components/TourInfoTop"
import guideInfo from "@/views/components/GuideInfo"
import titleLink from "@/views/components/TitleLink"
export default {
  components: {
    card,
    tourInfoTop,
    guideInfo,
    titleLink
  },
  computed: {
    ...mapGetters('feedback', ['currentFeedback']),
    ...mapGetters('wode', {
      user: 'getPersonInfo'
    })
  },
  created() {
    const id = this.$route.params.id
    this.$store.dispatch('wode/getPersonInfo')
    this.$store.dispatch('feedback/get_feedback_by_id', id)
  },
  methods: {
    onClickBack() {
      this.$router.push({path: '/notification/main', query: {type: 2}})
    },
    toCompanyInfo(id) {
      this.$router.push(`/guider/companyInfo/${id}`)
    }
  }
}
</script>

<style scoped>
.feedback_title_name {
  font-size: .3rem;
}
.feedback_comment_tag {
  font-size: .2rem;
  color: #666666;
}
</style>